.mission-vision-values .mv-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.07);
  gap: 1rem;
}

.mission-vision-values .mv-icon {
  text-align: center;
  
}

.mission-vision-values h3 {
  text-align: center;
 
}

.mission-vision-values p {
  flex-grow: 1;
  text-align: center;
}

.mission-vision-values {
  padding: 50px 0;
  background-color: #e4e4e4;
}



.mv-icon img {
  width: 25%;
}

.mv-section h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  font-weight: bold;
  color: #000;
}

.mv-section p {
  font-size: 1.1rem;
  color: #000;
}

.kr-mission-section,
.kr-vision-section,
.kr-values-section {
  padding: 30px;
  color: #000;
}

@media (max-width: 768px) {
  .mission-vision-values .col-md-4 {
    margin-bottom: 20px; /* Add space between columns */
  }

  /* Optionally, remove margin-bottom from the last column to avoid extra space */
  .mission-vision-values .col-md-4:last-child {
    margin-bottom: 0;
  }
}

