/* src/internshipProgram.css */

.internship-program {
 
    padding: 20px;
  }
  
  .internship-card {
    transition: transform 0.3s ease;
  }
  
  .internship-card:hover {
    transform: translateY(-10px); /* Hover effect for interactivity */
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  