.intro-section {
    background: #eee;
    color: #555;  
    text-align: center;
    padding: 40px 0;
  }
  
  .intro-content h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .intro-content p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .intro-section {
      padding: 20px 0;
    }
    .intro-content h1 {
      font-size: 2rem;
    }
    .intro-content p {
      font-size: 1rem;
    }
  }
  