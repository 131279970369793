body {
  font-family: 'Poppins' sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: 0; font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}




/* src/components/Header.css */

.navbar-brand{font-size: 2.5rem !important; font-weight: 900;}

.navbar-logo img {
  max-height: 40px; /* Adjust as needed */
  margin-right: 10px;
}


.nav-link {
  font-weight: 500 !important;
  margin-left: 2rem; /* Adjust the margin as needed */
}


/* General Offcanvas Styles for Tablet and Mobile */
.offcanvas-custom {
  width: 250px; /* Default width for tablet */
  transition: transform 0.3s ease-in-out; /* Smooth slide-in animation */
  transform: translateX(100%); /* Initially off-screen */
}

/* Offcanvas Header Styles */
.offcanvas-header-custom {
  background-color: #004588;
  color: #ffffff;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Offcanvas Body Styles */
.offcanvas-body {
  padding: 1rem;
}

/* Tablet and Mobile Styles */
@media (max-width: 768px) {
  .offcanvas-custom {
    background-color: #343a40; /* Dark background for offcanvas */
    color: #fff; /* White text */
    width: 100%; /* Full width on smaller screens */
    height: 100vh; /* Full viewport height */
    max-width: none; /* Remove max-width constraint */
    transform: translateX(100%); /* Off-screen by default */
    right: 0; /* Align to the right side */
    position: fixed; /* Fixed position */
    top: 0; /* Align to the top of the viewport */
    z-index: 1050; /* Ensure it’s above other content */
  }

  .offcanvas-header-custom {
    background-color: #004588; /* Same background color */
    color: #ffffff; /* Same text color */
    padding: 1rem; /* Consistent padding */
    display: flex; /* Flex layout */
    justify-content: space-between; /* Space between header elements */
    align-items: center; /* Align items vertically */
  }

  .offcanvas-custom .nav-link {
    color: #7a7a7a; /* White text for nav links */
    transition: color 0.3s ease-in-out;
    padding: 1rem; /* Larger padding for better accessibility */
    border-bottom: 1px solid #6c757d; /* Divider line between links */
  }

  .offcanvas-custom .nav-link:hover {
    color: #004588; /* Hover color */
  }
  .offcanvas-header-custom .btn-close{color:#fff !important;}
  .banner-content {padding: 30px 0;}
  .navbar-brand, .banner-content h1{font-size: 2rem !important;}
  .offcanvas.show {
    transform: translateX(0); /* Slide-in effect */
  }

  .offcanvas.hide {
    transform: translateX(100%); /* Slide-out effect */
  }
}


/* src/pages/HomePageBanner.css */

.banner {
  background: #004097; 
  color: #fff;  
  text-align: left;
  position: relative;
  overflow: hidden;
}

.banner-content {
  z-index: 2;
  position: relative;
}

.banner h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.banner p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.banner .btn-primary {
  background-color: #ff3a2f;
  border-color: #ff3a2f;
  border-radius: 4px;
}

.banner .btn-primary:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  color:#000;
}

.banner-image {
  z-index: 1;
  position: relative;
  max-width: 100%;
}

@media (max-width: 768px) {
  .banner {
    text-align: center;
  }
  .banner-content {
    margin-bottom: 20px;
  }
  .banner h1 {
    font-size: 2rem;
  }
  .banner p {
    font-size: 1rem;
  }
}

/* src/components/OurServices.css */

.our-services {
  position: relative;
  padding: 50px 0;  
  color: white;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
  color: white;
}

.service-cards {
  margin-top: 40px;
}

.service-card {
  text-align: center;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  transition: transform 0.3s ease-in-out;
}

.service-card:hover {
  transform: translateY(-10px);
}
.service-card img {
  width:25%;
  margin: 20px auto; /* Center the icon horizontally */
  display: block; /* Ensure the image is a block element */
}










.portfolio-section {
  padding: 50px 0;
  background-color: #f8f9fa;
}
.portfolio-section .section-title{color:#000;}


.portfolio-card {
  margin-bottom: 30px;
  overflow: hidden; /* Ensure content doesn't overflow */
  border: none; /* Remove default card border */
  border-radius: 10px; /* Optional: Rounded corners for a modern look */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.portfolio-card:hover {
  transform: scale(1.05); /* Slight zoom-in effect */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}




.portfolio-card .card-body {
  padding: 15px; /* Optional: Add padding to card body */
  text-align: center; /* Center align text */
}

.portfolio-card .card-title {
  color: #000000;
}

.portfolio-card .card-text {
  color: #6c757d;
}


/* src/components/Testimonials.css */

/* src/components/Testimonials.css */

.testimonials {
  background-color: #e1e1e1;
  padding: 60px 0;
  text-align: center;
}

.testimonials .section-title{color:#000;}



.testimonial-item {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
  text-align: center;
}

.testimonial-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 auto 20px;
}

.testimonial-text {
  font-style: italic;
  margin-bottom: 20px;
}

.testimonial-name {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 1.5rem;
}

.testimonial-position {
  color: #666666;
}




/* src/components/Footer.css */

.footer {
  background-color: #00397a;
  color: white;
  padding: 50px 0;
}

.footer-col {
  margin-bottom: 20px;
}

.footer h5 {
  margin-bottom: 15px;
  font-weight: bold;
}

.footer p, .footer ul, .footer li, .footer a {
  color: white;
  margin-bottom: 5px;
}

.footer a {
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer ul {
  padding-left: 0;
  list-style: none;
}

.footer .text-center {
  margin-top: 20px;
}


