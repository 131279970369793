/* Custom Toggle Button */
.navbar-toggler {
  border: none; /* Remove border */
  padding: 0; /* Remove default padding */
}

.navbar-toggler-icon {
  width: 30px; /* Width of the toggle icon */
  height: 22px; /* Height of the toggle icon */
  position: relative; /* Position relative for pseudo-elements */
  display: inline-block; /* Inline block display */
  background-image: none; /* Remove default background */
}




.navbar-toggler-icon::before {
  width: 12px; /* Smallest line width */
  top: 0; /* Position at the top */
}

.navbar-toggler-icon div {
  width: 18px; /* Middle line width */
  top: 50%; /* Position in the middle */
  transform: translateY(-50%); /* Center vertically */
}

.navbar-toggler-icon::after {
  width: 24px; /* Longest line width */
  bottom: 0; /* Position at the bottom */
}

/* Logo Styling for Responsiveness */
.navbar-brand img {
  width: 190px; /* Logo size for small screens */
  max-width: 100%; /* Prevent overflow */
  height: auto; /* Maintain aspect ratio */
}

@media (min-width: 768px) {
  .navbar-brand img {
    width: 65%; /* Logo size for medium and larger screens */
  }
}
