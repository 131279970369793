/* Desktop Styles */
/* Base styles */
.welcome-section ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 1rem;
}

.welcome-section li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.welcome-section .list-icon {
  flex-shrink: 0; /* Prevent the icon from resizing */
  margin-right: 0.5rem; /* Add space between the icon and the text */
}

/* Ensure text content wraps properly */
.welcome-section li div {
  max-width: 100%;
  word-wrap: break-word;
}


.welcome-section {
  padding: 50px 0;
}

.welcome-section .image-col {
  text-align: left;
}

.welcome-left-section {
  display: flex;
  position: relative;
}

.left-pic1 {
  width: 50%;
}

.left-pic2 {
  width: 50%;
}

.experience-box2 h3 {
  color: #ffffff;
  font-size: 30px;
  margin-bottom: 1px;
}

.experience-box2 p {
  color: #ffffff;
  font-size: 14px;
}

.experience-box2 {
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  padding: 1rem;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 160px;
  top: 25%;
}

.experience-box2::before {
  content: "";
  border-radius: 6px;
  background-image: linear-gradient(0deg, rgb(8, 106, 216) 0%, rgb(55, 140, 255) 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.experience-box2 .inner-content {
  position: relative;
  z-index: 2;
}

.inner-content img {
  width: 60%;
}

.welcome-section .text-col {
  text-align: left;
}

.welcome-section h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.welcome-section .text-col h3 {
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 0;
}

.welcome-section p {
  font-size: 1rem;
  margin-bottom: 20px;
}



.welcome-section .btn-primary {
  background-color: #0e6edd;
  border-radius: 4px;
  padding: 10px 30px;
  font-size: 1rem;
}

.welcome-section .btn-primary:hover {
  background-color: #0050b3;
  border-color: #0050b3;
}



.custom-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.custom-list .list-icon {
  flex-shrink: 0;
  margin-right: 8px;
}

.custom-list div {
  max-width: 100%;
  word-wrap: break-word;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .inner-content img {
      width: 45%;
  }

  .welcome-section .text-col {
      text-align: center;
      padding-top: 75%;
  }

  .welcome-section h2 {
      font-size: 2rem;
  }

 

  .experience-box2 {
      bottom: -260px;
      width: 100%;
  }

  .image-col .left-pic1,
  .image-col .left-pic2 {
      display: none;
  }

  .custom-list li {
    align-items: center;
    flex-direction: column; /* Stack icon and text vertically */
    text-align: center;
}

.custom-list .list-icon {
    margin-right: 0;
    margin-bottom: 8px; /* Add space below the icon */
}

  .welcome-section .text-col h3 {
      font-size: 1.8rem;
      text-align: center;
  }
  .welcome-section li {
    align-items: center;
    flex-direction: column; 
    
  }

  .welcome-section .list-icon {
    margin-right: 0;
    margin-bottom: 8px; /* Add spacing below the icon */
  }

  .welcome-section p {
      font-size: 1rem;
      margin-bottom: 10px;
      display: block;
      text-align: center;
  }
}
