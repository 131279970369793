.project-details-page {
    padding: 60px 0;
    background-color: #f9f9f9;
  }
  
  .project-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .project-description {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .project-image {
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .visit-button {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .coming-soon-text {
    font-size: 1.2rem;
    color: #ff5722;
  }
  
  .feature-list {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .feature-list li {
    font-size: 1.1rem;
    color: #444;
    margin-bottom: 10px;
  }
  
  .project-not-found {
    text-align: center;
    font-size: 1.5rem;
    color: #ff0000;
    margin-top: 50px;
  }

  
  