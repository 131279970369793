.success-page {
    padding: 20px;
  }
  
  .success-banner {
    background-color: #d4edda;
    padding: 20px;
    text-align: center;
  }
  
  .success-banner h1 {
    color: #155724;
  }
  
  .success-content {
    margin-top: 20px;
  }
  
  .success-content h2 {
    margin-bottom: 10px;
    color: #28a745;
  }
  
  .success-content p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .success-content .btn-primary {
    margin-top: 20px;
  }
  