.banner-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.banner-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.banner-image {
  max-width: 100%;
  height: auto;
  opacity: 0; /* Start hidden */
  transform: translateY(20px); /* Initial position */
  transition: opacity 2s ease-in, transform 2s ease-in; /* Smooth fade-in and slide-up transition */
}

.banner-image.fade-in {
  opacity: 1; /* Fade in to fully visible */
  transform: translateY(0); /* Slide up to final position */
}

@media (max-width: 768px) {
  .banner-content {
    text-align: center;
  }

  .banner-image {
    width: 75%; /* Adjust the width if necessary */
    margin: 0 auto; /* Center the image */
  }

  .justify-content-end {
    justify-content: center !important; /* Center the image container */
  }
}
