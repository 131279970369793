

.contact-banner {
  background: linear-gradient(
      to bottom,
      rgba(50, 64, 148, 0.7),
      rgba(51, 174, 255, 0.7)
    );

  color: white;
  padding: 40px 0;
  text-align: center;
}

.contact-banner h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.contact-banner p {
  font-size: 20px;
}

.contact-form-section {
  padding: 60px 0;
  background-color: #f8f9fa;
}

.contact-info {
  
  margin-bottom: 30px;
}

.contact-info h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 15px;
}

.contact-info p {
  font-size: 1rem;
  line-height: 1.6;
}

.contact-form-section h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
}





/* Contact.css */
.input-wrapper {
  position: relative;
}

.valid-icon {
  color: green;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.invalid-icon {
  color: red;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}





