.footer {
  background-color: #00336c;
  color: white;
  padding: 50px 0;
}

.footer-col {
  margin-bottom: 20px;
  text-align: left; /* Align content to the left */
}

.footer h5 {
  margin-bottom: 15px;
  font-weight: bold;
}

.footer p,
.footer ul,
.footer li,
.footer a {
  color: white;
  margin-bottom: 5px;
}

.footer a {
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer ul {
  padding-left: 0;
  list-style: none;
}

.footer .text-center {
  margin-top: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-col {
    margin-bottom: 30px;
    text-align: left; /* Keep content aligned to the left on mobile */
  }

  .footer h5 {
    font-size: 1.2rem;
  }

  .footer p,
  .footer ul,
  .footer li,
  .footer a {
    font-size: 0.9rem;
  }

  .footer ul {
    padding-left: 0;
    list-style: none;
  }

  .footer ul li {
    display: block;
    margin-right: 0;
    margin-bottom: 10px; /* Added margin for better spacing */
  }

  /* Adjust social icons size */
  .footer .btn-square {
    width: 38px;
    height: 38px;
    line-height: 24px;
    display: flex;
    justify-content: center;
  }

  .footer .btn-square i {
    font-size: 1.2rem;
  }

  /* Center the copyright text */
  .footer .text-center {
    margin-top: 30px;
    font-size: 0.8rem; /* Adjust footer copyright text size */
    text-align: center; /* Center only the copyright text */
  }
}
