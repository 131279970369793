/* SharedButton.css */
.kr-primary-button {
    background: #0c3596 !important;
    border: none !important;
    border-radius: 0 !important;
    padding: 9px 18px !important;
    margin-top: 1rem;
  }
  
  .kr-primary-button:hover {
    background-color: #0056b3; /* Darker hover color */
  }
  