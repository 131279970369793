.about-page .about-header {
  padding: 60px 0;
  background: #f8f9fa;
  text-align: center;
}

.about-page .about-header h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.about-page .about-header p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.about-page .about-section {
  padding: 60px 0;
}

.about-page .about-item {
  text-align: center;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%; /* Ensures equal height */
}

.about-page .about-item img.about-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.about-page .about-item h3{
  font-size: 1.8rem;
    margin-bottom: 15px;
    font-weight: bold;
    color: #000;
}

.about-page .about-item p {
  font-size: 1rem;
  flex-grow: 1; /* Ensures the text takes up available space */
}

.about-page .team-section {
  padding: 60px 0;
  background: #f8f9fa;
  text-align: center;
}

.about-page .team-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.about-page .team-member {
  text-align: center;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}
.team-member i{ font-size: 4rem;}

.about-page .team-member-img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.about-page .team-member-name {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.about-page .team-member-role {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.about-page .team-member-bio {
  font-size: 1rem;
  color: #666;
}

.team-member-image {
  width: 50%; /* Adjust as needed */
  border-radius: 50%; /* Make it circular, if desired */
  margin-bottom: 15px; /* Space between image and text */
}