.page-banner {
  background: linear-gradient(to bottom, rgba(50, 64, 148, 0.7), rgba(51, 174, 255, 0.7));
  color: white;
  padding: 30px 0;
  text-align: left;
  }
  
  .page-banner h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0;
  }
  
  @media (max-width: 768px) {
    .page-banner {
      text-align: center;
    }
    .page-banner h1 {
      font-size: 2rem;
    }
  }
  