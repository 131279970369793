/* General Styles */
.services-page {  
  color: #333333; /* Primary text color */
}

/* Header Banner */
.service-banner {
  position: relative;
  background: linear-gradient(to right, #003366, #00aaff); /* Gradient background */
  height: 150px; /* Height for banner */
  background-size: cover;
  background-position: center;
}


.intro-content {
  max-width: 800px; /* Limit width */
  margin: 0 auto;
}

.intro-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.intro-content p {
  font-size: 1rem;
  color: #000;
}

/* Service Section */
.service-section {
  padding: 60px 0;
 
}

.service-item { 
  text-align: center;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);  
  margin-bottom: 20px; 
  height: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}


.service-description{color:#000;}
.service-title {
  color: #000; /* Main color */
  margin-bottom: 15px;
}
.custom-icon{color:#000; font-size: 64px;}
.service-item p {
  color: #666666; /* Secondary text color */
}



.service-item h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0;
 
}



@media (max-width: 768px) {
  .service-item {
    margin-bottom: 20px;
  }
}
.icon-modern {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #6de8cf;
  padding: 1rem;
}


